import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	show: {
		// backgroundColor: "white",
		color: "white",
		// paddingRight: "20px",
		// marginBottom: "50px",
		height: "fit-content",
		width: "100%",
		boxShadow: "0 0 20px 1px rgb(0 0 0 / 10%)",
		// borderRadius: "10px",
		display: "flex",
		// justifyContent: "left",
		justifyContent: "center",
		textAlign: "left",
		position: "relative",
		// borderBottom: "1px solid gray",
		borderTop: "1px solid gray",
	},
	prevShow: {
		// backgroundColor: "white",
		color: "gray",
		// paddingRight: "20px",
		// marginBottom: "50px",
		height: "fit-content",
		width: "100%",
		boxShadow: "0 0 20px 1px rgb(0 0 0 / 10%)",
		// borderRadius: "10px",
		display: "flex",
		// justifyContent: "left",
		justifyContent: "center",
		textAlign: "left",
		position: "relative",
		// borderBottom: "1px solid gray",
		borderTop: "1px solid gray",
	},
	final: {
		borderBottom: "1px solid gray",
	},
	left: {
		display: "flex",
		flexDirection: "column",
		justfyContent: "start",
	},
	soloShow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "80px",
	},
	soloShowLogo: {
		width: "70px",
		height: "70px",
		// backgroundColor: "#044692",
		// borderRadius: "50%",
		display: "flex",
		// flexDirection: "column",
		justifyContent: "center",
		// alignItems: "center",
		// color: "white",
	},
	soloAlert: {
		width: "100px",
		display: "flex",
		justifyContent: "center",
		marginBottom: "10px",
		borderRadius: "20px !important",
		padding: "0 10px !important",
		backgroundColor: "#000 !important",
		border: "1px solid #fff",
		// backgroundColor: "#555 !important",
		// fontWeight: "bold !important"
	},
	soloAlertPrev: {
		width: "100px",
		display: "flex",
		justifyContent: "center",
		marginBottom: "10px",
		borderRadius: "20px !important",
		padding: "0 10px !important",
		backgroundColor: "#000 !important",
		border: "1px solid gray !important",
		color: "gray !important",
		// backgroundColor: "#555 !important",
		// fontWeight: "bold !important"
	},
	date: {
		height: "80px",
		width: "80px",
		backgroundColor: "black",
		// borderRadius: "10px",
		padding: "10px",
		display: "relative",
	},
	weekDay: {
		height: "30%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSize: "20px",
	},
	day: {
		height: "60%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSize: "50px",
	},
	details: {
		padding: "12px 20px 8px 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		textAlign: "center",
	},
	fullDate: {
		fontSize: "14pt",
	},
	venue: {
		fontSize: "20pt",
		fontWeight: "bold",
		lineHeight: "110%",
		marginTop: "5px",
	},
	other_artists: {
		fontSize: "16pt",
		// fontWeight: "bold",
		lineHeight: "110%",
		marginTop: "5px",
		marginBottom: "15px",
	},
	address: {
		fontSize: "12pt",
		lineHeight: "125%",
		marginTop: "12px",
		marginBottom: "10px",
	},
	venueLink: {
		textDecoration: "none",
		color: "blue",
		fontSize: "14pt",
		// lineHeight: "225%",
		display: "flex",
		alignItems: "center",
	},
	newTabIcon: {
		marginLeft: "8px",
		height: "13px",
	},
	showLinks: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginTop: "5px",
	},
	ticketBtn: {
		// position: "absolute",
		right: "18px",
		bottom: "10%",
		backgroundColor: "#DD0000",
		// backgroundColor: "rgba(0,0,0,0)",
		border: "2px solid #DD0000",
		textDecoration: "none",
		padding: "6px 12px",
		borderRadius: "5px",
		color: "white",
		// color: "#DD0000",
		fontWeight: "bold",
		marginBottom: "12px",
	},
}));
