import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	showLg: {
		// backgroundColor: "white",
		color: "white",
		padding: "20px",
		// marginBottom: "50px",
		height: "fit-content",
		// height: "200px",
		width: "55%",
		boxShadow: "0 0 20px 1px rgb(0 0 0 / 10%)",
		// borderRadius: "10px",
		display: "flex",
		justifyContent: "left",
		textAlign: "left",
		position: "relative",
		// borderBottom: "1px solid gray",
		borderTop: "1px solid gray",
	},
	showMd: {
		// backgroundColor: "white",
		color: "white",
		padding: "20px",
		// marginBottom: "50px",
		height: "fit-content",
		// height: "200px",
		width: "80%",
		boxShadow: "0 0 20px 1px rgb(0 0 0 / 10%)",
		// borderRadius: "10px",
		display: "flex",
		justifyContent: "left",
		textAlign: "left",
		position: "relative",
		// borderBottom: "1px solid gray",
		borderTop: "1px solid gray",
	},
	prevShowLg: {
		// backgroundColor: "white",
		color: "gray !important",
		padding: "20px",
		// marginBottom: "50px",
		height: "200px",
		width: "55%",
		boxShadow: "0 0 20px 1px rgb(0 0 0 / 10%)",
		// borderRadius: "10px",
		display: "flex",
		justifyContent: "left",
		textAlign: "left",
		position: "relative",
		// borderBottom: "1px solid gray",
		borderTop: "1px solid gray",
	},
	prevShowMd: {
		// backgroundColor: "white",
		color: "gray !important",
		padding: "20px",
		// marginBottom: "50px",
		height: "200px",
		width: "80%",
		boxShadow: "0 0 20px 1px rgb(0 0 0 / 10%)",
		// borderRadius: "10px",
		display: "flex",
		justifyContent: "left",
		textAlign: "left",
		position: "relative",
		// borderBottom: "1px solid gray",
		borderTop: "1px solid gray",
	},
	final: {
		borderBottom: "1px solid gray",
	},
	soloShow: {
		position: "absolute",
		top: "20px",
		right: "20px",
		textAlign: "center",
	},
	soloShowLogo: {
		width: "70px",
		height: "70px",
		// backgroundColor: "#044692",
		// borderRadius: "50%",
		display: "flex",
		// flexDirection: "column",
		justifyContent: "center",
		// color: "white",
	},
	soloAlert: {
		// position: "absolute",
		right: "2%",
		top: "20px",
		width: "15%",
		display: "flex",
		justifyContent: "center",
		// marginBottom: "10px",
		borderRadius: "20px !important",
		padding: "0 10px !important",
		backgroundColor: "#000 !important",
		border: "1px solid #fff",
		// backgroundColor: "#555 !important",
		// fontWeight: "bold !important"
	},
	soloAlertPrev: {
		// position: "absolute",
		right: "2%",
		top: "20px",
		width: "15%",
		display: "flex",
		justifyContent: "center",
		// marginBottom: "10px",
		borderRadius: "20px !important",
		padding: "0 10px !important",
		backgroundColor: "#000 !important",
		border: "1px solid gray !important",
		color: "gray !important",
		// backgroundColor: "#555 !important",
		// fontWeight: "bold !important"
	},
	date: {
		height: "200px",
		width: "200px",
		backgroundColor: "black",
		borderRadius: "10px",
		display: "relative",
	},
	weekDay: {
		height: "30%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSize: "33px",
	},
	prevWeekDay: {
		height: "30%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "gray",
		fontSize: "33px",
	},
	day: {
		height: "60%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSize: "100px",
	},
	prevDay: {
		height: "60%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "gray",
		fontSize: "100px",
	},
	details: {
		padding: "0 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		width: "100%",
	},
	fullDate: {
		fontSize: "18pt",
	},
	venue: {
		fontSize: "30pt",
		fontWeight: "bold",
	},
	other_artists: {
		fontSize: "16pt",
		fontWeight: "bold",
		marginTop: "10px",
		marginBottom: "16px",
	},
	address: {
		fontSize: "14pt",
	},
	venueLink: {
		textDecoration: "none",
		color: "blue",
		fontSize: "14pt",
		display: "flex",
		alignItems: "center",
	},
	newTabIcon: {
		marginLeft: "8px",
		height: "13px",
	},
	showLinks: {
		position: "relative",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	},
	ticketBtn: {
		position: "absolute",
		right: "2px",
		// right: "50%",
		// transform: "translateX(-50%)",
		bottom: "0%",
		backgroundColor: "#DD0000",
		textDecoration: "none",
		padding: "6px 12px",
		borderRadius: "5px",
		color: "white",
		fontWeight: "bold",
	},
}));
