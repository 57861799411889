import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	main: {
		marginTop: "18%",
		// height: "100%",
		width: "100%",
		// position: "absolute",
	},
	homeMain: {
		// marginTop: "30%",
	},
	innerDiv: {
		padding: "0 5%",
	},
}));
